import { Tooltip } from "antd";
import Customize from "../../Assets/img/icons/customize.png";
import SaveUs from "../../Assets/img/icons/download.png";
import { useState } from "react";
import { searchTooltip } from "../../Utils/tooltipTexts";
import { useDispatch, useSelector } from "react-redux";
import { ExportExcelThunk } from "../../Thunks";
import {
  getExportedData,
  getSelectedAssets,
} from "../../Reducer/ListeningReducer";
import { useEffect } from "react";
import { exportToCSV } from "../../Utils/ExportListing/ExportListing";

const tabsNames = {
  1: "listingRealVolume",
  2: "listingMarketCap",
  3: "listingHoldersConcentration",
  4: "listingMaturity",
  5: "listingSecurity",
  6: "listingGeneral",
};

export const ListingSearchAndFilter = ({
  setIsModalOpen,
  setModalType,
  getSearchData,
  searchData,
}) => {
  const dispatch = useDispatch();
  const customizeClick = () => {
    setIsModalOpen(true);
    setModalType("customizeAssets");
  };
  const assets = useSelector(getSelectedAssets);

  const exportData = useSelector(getExportedData);

  useEffect(() => {
    dispatch(ExportExcelThunk());
  }, [assets]);

  const [visible, setVisible] = useState(false);

  const openAssetModal = () => {
    setModalType("assets");
    setIsModalOpen(true);
  };

  return (
    <div className="listing_search_row">
      <div className="listening_search_wrapper">
        <div className="listening_search_icon_wrapper" />
        <input
          type="text"
          value={searchData}
          onChange={(e) => getSearchData(e.target.value)}
          className="listening_search_field"
          placeholder="Search..."
        />
        <Tooltip
          placement="rightTop"
          title={searchTooltip}
          color={"#364048"}
          onOpenChange={(visible) => setVisible(visible)}
        >
          <i
            className="icon-Vector"
            style={{ position: "relative", left: "10%", top: "8px" }}
          ></i>
        </Tooltip>
      </div>

      <div className="listing_export_customize_columns_wrapper">
        <button
          className="listing_customize listing_saveUs"
          onClick={() => exportToCSV(exportData)}
        >
          <img
            src={SaveUs}
            className="listing_customize_icon"
            alt="customize"
          />
          Export
        </button>
        <button className="listing_customize" onClick={customizeClick}>
          <img
            src={Customize}
            className="listing_customize_icon"
            alt="customize"
          />
          Assets
        </button>
      </div>
    </div>
  );
};

{
  /* <button className="listing_export">
                    <img
                        src={Export}
                        className="listing_export_icon"
                        alt="export"
                    />
                    Export
                </button> */
}
